import React from 'react'

export default function({className = '', ...rest}) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" {...rest}>
        <path d="M180.148 0H37.926a4.742 4.742 0 0 0-4.74 4.741v246.518A4.742 4.742 0 0 0 37.926 256h180.148a4.742 4.742 0 0 0 4.741-4.741V42.666z" fill="#eff2fa"/>
        <path d="M203.852 241.778H52.148a4.742 4.742 0 0 1-4.741-4.741V18.963a4.742 4.742 0 0 1 4.741-4.741h128c15.709 0 28.445 12.735 28.445 28.445v194.37a4.742 4.742 0 0 1-4.741 4.741z" fill="#fff"/>
        <path d="M203.852 203.852H52.148a4.742 4.742 0 0 0-4.741 4.741v28.445a4.742 4.742 0 0 0 4.741 4.741h151.704a4.742 4.742 0 0 0 4.741-4.741v-28.445a4.742 4.742 0 0 0-4.741-4.741z" fill="#ff6464"/>
        <path d="M222.814 42.666h-37.926a4.742 4.742 0 0 1-4.741-4.741V0z" fill="#d7deed"/>
        <path d="M189.629 227.555H66.37a4.742 4.742 0 0 1-4.741-4.741h0a4.742 4.742 0 0 1 4.741-4.741h123.259a4.742 4.742 0 0 1 4.741 4.741h0a4.742 4.742 0 0 1-4.741 4.741z" fill="#d2555a"/>
        <circle cx="85.333" cy="222.815" r="9.481" fill="#fff"/>
        <path d="M161.739 115.835l-51.224 25.612c-5.035 2.518-10.96-1.144-10.96-6.773V83.449c0-5.63 5.925-9.291 10.96-6.773l51.224 25.612c5.582 2.79 5.582 10.756 0 13.547z" fill="#ff6464"/>
    </svg>
  )
}