import React from 'react'

export default function({className = ''}) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <path d="m169.07 0h-132.67c-3.6937 0-6.688 2.9943-6.688 8.8046v242.62c0 1.5771 2.9943 4.5714 6.688 4.5714h183.2c3.6937 0 6.688-2.9943 6.688-4.5714v-192.1c0-3.1817-0.42514-4.2057-1.1749-4.96l-53.193-53.193c-0.75429-0.74971-1.7783-1.1749-2.8434-1.1749z" fill="#e9e9e0" stroke-width="4.5714"/>
      <polygon transform="scale(4.5714)" points="37.5 0.151 37.5 12 49.349 12" fill="#d9d7ca"/>
      <path d="m219.6 256h-183.2c-3.6937 0-6.688-2.9943-6.688-6.688v-71.026h196.57v71.026c0 3.6937-2.9943 6.688-6.688 6.688z" fill="#f6712e" stroke-width="4.5714"/>
      <g fill="#fff" stroke-width="4.5714">
        <path d="m80.37 242.29h-7.5017v-46.062h13.248c1.9566 0 3.8949 0.31086 5.8103 0.93714 1.9154 0.62629 3.6343 1.5634 5.1566 2.8114s2.752 2.7611 3.6891 4.5303c0.93714 1.7691 1.408 3.7577 1.408 5.9703 0 2.336-0.39771 4.448-1.1886 6.3452-0.79086 1.8971-1.8971 3.4926-3.3143 4.7817-1.4171 1.2891-3.1269 2.2903-5.1246 2.9989s-4.2103 1.0606-6.624 1.0606h-5.5634v16.626zm0-40.375v18.249h6.8754c0.91428 0 1.8194-0.15543 2.72-0.47086 0.896-0.31085 1.7189-0.82285 2.4686-1.5314 0.74971-0.70857 1.3531-1.696 1.8103-2.9668 0.45714-1.2709 0.68571-2.8434 0.68571-4.7177 0-0.74971-0.10514-1.6183-0.31086-2.592-0.21029-0.97828-0.63543-1.9154-1.28-2.8114-0.64914-0.896-1.5543-1.6457-2.72-2.2491s-2.7109-0.90514-4.6263-0.90514h-5.6229z"/>
        <path d="m118.19 242.29h-7.5017v-46.062h13.248c1.9566 0 3.8949 0.31086 5.8103 0.93714 1.9154 0.62629 3.6343 1.5634 5.1566 2.8114s2.752 2.7611 3.6891 4.5303c0.93715 1.7691 1.408 3.7577 1.408 5.9703 0 2.336-0.39771 4.448-1.1886 6.3452-0.79086 1.8971-1.8971 3.4926-3.3143 4.7817-1.4172 1.2891-3.1269 2.2903-5.1246 2.9989-1.9977 0.70857-4.2103 1.0606-6.624 1.0606h-5.5634v16.626zm0-40.375v18.249h6.8754c0.91428 0 1.8194-0.15543 2.72-0.47086 0.89599-0.31085 1.7188-0.82285 2.4686-1.5314 0.74971-0.70857 1.3531-1.696 1.8103-2.9668 0.45715-1.2709 0.68572-2.8434 0.68572-4.7177 0-0.74971-0.10515-1.6183-0.31086-2.592-0.21029-0.97828-0.63543-1.9154-1.28-2.8114-0.64914-0.896-1.5543-1.6457-2.72-2.2491-1.1657-0.60343-2.7108-0.90514-4.6263-0.90514h-5.6229z"/>
        <path d="m181.06 196.22v5.1246h-13.751v40.937h-7.5612v-40.937h-13.751v-5.1246z"/>
      </g>
      <g fill="#c8bdb8" stroke-width="4.5714">
        <path d="m180.57 137.14h-109.71v-73.143h109.71zm-100.57-9.1429h91.429v-54.857h-91.429z"/>
        <path d="m93.71 160c-0.8 0-1.6137-0.21029-2.3497-0.65371-2.1669-1.2983-2.8663-4.1052-1.568-6.272l13.714-22.857c1.2983-2.1668 4.1051-2.8663 6.272-1.568 2.1668 1.2983 2.8663 4.1052 1.568 6.272l-13.714 22.857c-0.85486 1.4308-2.368 2.2217-3.9223 2.2217z"/>
        <path d="m157.72 160c-1.5543 0-3.0674-0.79086-3.9223-2.2171l-13.714-22.857c-1.2983-2.1668-0.59886-4.9737 1.568-6.272 2.1669-1.2937 4.9737-0.59885 6.272 1.568l13.714 22.857c1.2983 2.1668 0.59885 4.9737-1.568 6.272-0.736 0.43885-1.5497 0.64914-2.3497 0.64914z"/>
        <path d="m125.71 73.143c-2.5234 0-4.5714-2.0434-4.5714-4.5714v-13.714c0-2.528 2.048-4.5714 4.5714-4.5714 2.5234 0 4.5714 2.0434 4.5714 4.5714v13.714c0 2.528-2.048 4.5714-4.5714 4.5714z"/>
      </g>
      <rect x="80" y="73.143" width="91.429" height="54.857" fill="#d3ccc9" stroke-width="4.5714"/>
    </svg>
  )
}